import seedrandom from 'seedrandom';

const mutateShuffle = <T>(arr: T[], rng: () => number = Math.random) => {
	for (let i = arr.length - 1; i > 0; i--) {
		const j = Math.floor(rng() * (i + 1));
		[arr[i], arr[j]] = [arr[j]!, arr[i]!];
	}
};

const shuffle = <T>(arr: T[], rng: () => number = Math.random): T[] => {
	const newArr = [...arr];
	mutateShuffle(newArr, rng);
	return newArr;
};

export const deterministicShuffle = <T>(arr: T[], seed: string): T[] =>
	shuffle(arr, seedrandom(seed));
