import { ImageProps } from 'expo-image';
import React, { createContext, useContext, useMemo } from 'react';

import useChooseTherapistPollResponse from '../hooks/useChooseTherapistPollResponse';

type TherapistContextType = {
	therapistImage?: ImageProps['source'];
	therapistName?: string;
	therapistChosen?: boolean;
};

const TherapistContext = createContext<TherapistContextType>({});

export const TherapistProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { therapistImage, therapistName, therapistChosen } =
		useChooseTherapistPollResponse();
	const context = useMemo(
		() => ({ therapistImage, therapistName, therapistChosen }),
		[therapistImage, therapistName, therapistChosen],
	);

	return (
		<TherapistContext.Provider value={context}>
			{children}
		</TherapistContext.Provider>
	);
};

export const useTherapist = () => {
	return useContext(TherapistContext);
};
