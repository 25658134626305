import { ApiClient as MojoClient } from '@wearemojo/api-client';
import Cher from '@wearemojo/cher';
import { LoadingIndicator } from '@wearemojo/ui-components';
import { nativeApplicationVersion } from 'expo-application';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { ApiClientContext } from '../api';
import { useWhoopsHandler } from '../errors/WhoopsProvider';
import useBackendConfig from '../hooks/useBackendConfig';
import useBackendRoutingStyle from '../hooks/useBackendRoutingStyle';
import { SecureStorage } from '../storage';
import ApiClients, { ApiClient } from '../store/api/ApiClients';
import { logger } from '../utils/logging';
import { useClientContext } from './ClientContextProvider';

const DataProviders = ({ children }: { children: ReactNode }) => {
	const backendConfig = useBackendConfig();
	const routingStyle = useBackendRoutingStyle();
	const context = useClientContext();
	const whoops = useWhoopsHandler();
	const [apiClient, setApiClient] = useState<MojoClient | undefined>(undefined);
	const backendConfigRef = useRef(backendConfig);
	const whoopsRef = useRef(whoops);

	useEffect(() => {
		whoopsRef.current = whoops;
	}, [whoops]);

	useEffect(() => {
		if (!context || !routingStyle) {
			return;
		}

		if (apiClient && backendConfig === backendConfigRef.current) {
			apiClient.setContext(context);
			return;
		}

		backendConfigRef.current = backendConfig;

		const create = async () => {
			console.debug(`DataProviders create - routingStyle: ${routingStyle}`);
			const additionalHeaders: Record<string, string> = {};
			if (nativeApplicationVersion) {
				// replace with infra-client-version? (different format)
				additionalHeaders['mojo-rn-client-version'] = nativeApplicationVersion;
			}
			const client = await MojoClient.create({
				backendConfig,
				routingStyle,
				additionalHeaders,
				clientId: __DEV__
					? 'authclient_000000CPdjFjA2gl0cZEfQjVrploG' // Dev authclient ID: access tokens are extremely short lived
					: 'authclient_000000CMEvq5ORWGarBMHJYlm4Bv6',
				clientContext: context,
				storageProvider: SecureStorage,
			});
			ApiClients.setClient(ApiClient.mojo, client);

			setApiClient(client);
		};
		create().catch((error) => {
			logger.captureWarning(
				'DataProviders: failed to create MojoClient',
				error,
			);
			whoopsRef.current(
				Cher.parseOrWrap(error, 'failed_to_create_mojo_client'),
			);
		});
	}, [apiClient, backendConfig, routingStyle, context]);

	if (!context) {
		return <LoadingIndicator label="Client context" />;
	}

	if (!apiClient) {
		return <LoadingIndicator label="Mojo API client" />;
	}

	if (!apiClient.getAuthSession().sessionId) {
		return <LoadingIndicator label="Auth session" />;
	}

	return (
		<ApiClientContext.Provider value={apiClient}>
			{children}
		</ApiClientContext.Provider>
	);
};

export default DataProviders;
