import {
	Action,
	combineReducers,
	configureStore,
	createAction,
	Store,
	ThunkAction,
	UnknownAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
	createMigrate,
	FLUSH,
	MigrationManifest,
	PAUSE,
	PERSIST,
	PersistConfig,
	PersistedState,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { SecureStorage } from '../storage';
import { logger } from '../utils/logging';
import activity from './activity';
import api from './api';
import challengeProgress from './challengeProgress';
import dev from './dev';
import efficacymeasurement from './efficacymeasurement';
import loggedOutEvents from './loggedOutEvents';
import navigation from './navigation';
import once from './once';
import planCheckIn from './planCheckIn';
import session from './session';
import settings from './settings';
import { AppState } from './types';

export const PERSIST_KEY = '__MOJO_REDUX_STATE__';
export const PERSISTED_REDUCERS = [
	'settings',
	'dev',
	'once',
	'activity',
	'challengeProgress',
];

const combinedReducer = combineReducers({
	[api.reducerPath]: api.reducer,
	[dev.name]: dev.reducer,
	[efficacymeasurement.name]: efficacymeasurement.reducer,
	[navigation.name]: navigation.reducer,
	[once.name]: once.reducer,
	[session.name]: session.reducer,
	[settings.name]: settings.reducer,
	[activity.name]: activity.reducer,
	[loggedOutEvents.name]: loggedOutEvents.reducer,
	[challengeProgress.name]: challengeProgress.reducer,
	[planCheckIn.name]: planCheckIn.reducer,
});

export const resetStore = createAction('root/resetStore');
const rootReducer = (state: AppState | undefined, action: Action) =>
	combinedReducer(action.type === resetStore.type ? undefined : state, action);

type PersistAppState = (AppState & PersistedState) | undefined;

const migrations: MigrationManifest = {
	1: (state) => {
		return state;
	},
	2: (state) => {
		const currentState = state as PersistAppState;
		if (!currentState) {
			return undefined;
		}
		// Migrates redux store to remove "light" theme mode as we now have only one theme
		return {
			...currentState,
			settings: {
				...currentState.settings,
				theme: undefined,
			},
		} satisfies PersistAppState;
	},
};

const persistConfig: PersistConfig<AppState> = {
	key: PERSIST_KEY,
	version: 2,
	storage: SecureStorage,
	keyPrefix: '', // Drop default `persist:` prefix, keys cannot contain colons with expo-secure-store
	stateReconciler: autoMergeLevel2,
	whitelist: PERSISTED_REDUCERS,
	migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const RTK_QUERY_ACTIONS = [
	'fulfilled',
	'pending',
	'rejected',
	'uninitialized',
].map((action) => `${api.reducerPath}/executeQuery/${action}`);

const REDUX_PERSIST_ACTIONS = [
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
];

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (defaultMiddleware) =>
		defaultMiddleware({
			serializableCheck: {
				ignoredPaths: [api.reducerPath],
				ignoredActions: [...REDUX_PERSIST_ACTIONS, ...RTK_QUERY_ACTIONS],
			},
		}).concat(api.middleware),
});

setupListeners(store.dispatch);
export const persistor = persistStore(store as Store);

logger.setEnabledConfig({ ...store.getState().dev.enabledLogs });

export type AppDispatch = typeof store.dispatch & ((action: AppThunk) => void);
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	AppState,
	unknown,
	UnknownAction
>;
