import { createSlice } from '@reduxjs/toolkit';

import { AppState, PlanCheckIn, PlanCheckInAnswer } from './types';

const initialState: PlanCheckIn = {
	answers: [],
};

const planCheckInSlice = createSlice({
	name: 'planCheckIn',
	initialState,
	reducers: {
		clearPlanCheckInAnswers: (state) => {
			state.answers = [];
		},
		recordPlanCheckInAnswer: (
			state,
			action: {
				payload: PlanCheckInAnswer;
			},
		) => {
			const { strategyKey } = action.payload;
			const existingAnswerIndex = state.answers.findIndex(
				(answer) => answer.strategyKey === strategyKey,
			);
			if (existingAnswerIndex === -1) {
				state.answers.push(action.payload);
			} else {
				state.answers[existingAnswerIndex] = action.payload;
			}
		},
	},
});

export const { clearPlanCheckInAnswers, recordPlanCheckInAnswer } =
	planCheckInSlice.actions;

export const selectPlanCheckInAnswers = (state: AppState) =>
	state.planCheckIn.answers;

export default planCheckInSlice;
