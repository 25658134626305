import { LinkingOptions } from '@react-navigation/native';

import { APP_VARIANT } from '../../environment';
import { createURL } from '../utils/appLinking';
import NavigatorKey from './NavigatorKey';
import { RootParamList } from './params';
import ScreenKey from './ScreenKey';

type Linking = LinkingOptions<RootParamList>;

// match to app.config.ts
const universalAppLinkHostsProd = ['app.mojo.so', 'app.wearemojo.com'] as const;
const universalAppLinkHostsNonprod = ['app.mojo-nonprod.com'] as const;

export const universalAppLinkHosts = APP_VARIANT
	? universalAppLinkHostsNonprod
	: universalAppLinkHostsProd;

const prefixes = [
	__DEV__
		? 'wearemojo-rn-client-dev:///'
		: createURL('/', { isTripleSlashed: true }),

	...universalAppLinkHosts.map((host) => `https://${host}`),
];

const config: Linking['config'] = {
	screens: {
		[ScreenKey.AuthRecoverAccount]: 'auth/account-recovery/:provider?',
		[ScreenKey.AuthRedirectApple]: 'auth/apple-redirect',
		[ScreenKey.AuthRedirectMagicLink]: 'auth/magic-link-redirect',
		[ScreenKey.Auth]: 'auth/:mode',
		[ScreenKey.AuthMobileIntro]: 'auth/mobile-intro',
		[ScreenKey.Consent]: 'consent',
		[ScreenKey.CelebrateCheckout]: 'celebrate',
		[ScreenKey.ReferralPartner]: 'referral-partner',
		[ScreenKey.AppNudge]: 'app-nudge',
		[ScreenKey.AppReview]: 'app-review',
		[ScreenKey.DiscourseSso]: 'discourse-sso',
		[ScreenKey.GoNative]: '_/go-native',
		[ScreenKey.GoWeb]: '_/go-web',
		[ScreenKey.GroupSessions]: 'community/group-sessions',
		[ScreenKey.CommunityCreateTopic]: 'community/topics/new',
		[ScreenKey.CommunityTopic]: 'community/topics/:topicId',
		[ScreenKey.CommunityExpertNote]: 'community/topics/:topicId/expert-note',
		[ScreenKey.EfficacyQuestions]: {
			path: 'efficacy/:questionNo',
			parse: {
				questionNo: Number,
			},
		},
		[ScreenKey.AuthRegisterPreface]: {
			path: 'auth/register/preface/:flowStepId?/:flowStepIndex?/:subPath?',
			parse: {
				flowStepIndex: Number,
			},
		},
		[ScreenKey.Welcome]: {
			path: 'welcome/:flowStepId?/:flowStepIndex?/:subPath?',
			parse: {
				flowStepIndex: Number,
			},
		},
		[ScreenKey.CheckIn]: {
			path: 'check-in/:flowStepId?/:flowStepIndex?/:subPath?',
			parse: {
				flowStepIndex: Number,
			},
		},
		[ScreenKey.HomeRedirect]: 'home/redirect',
		[ScreenKey.Goal]: 'goal',
		[ScreenKey.Refer]: 'refer',
		[ScreenKey.SwapActivityQuestions]: {
			path: 'swap-activity',
			parse: { swapIndex: Number },
		},
		[NavigatorKey.AccountNavigator]: {
			screens: {
				[ScreenKey.Account]: 'account',
				[ScreenKey.AccountSettings]: 'account/settings',
				[ScreenKey.AccountManageBilling]: 'account/billing',
				[ScreenKey.AccountPreferences]: 'account/preferences',
				[ScreenKey.AccountLegal]: 'account/legal',
			},
		},
		[NavigatorKey.MainNavigator]: {
			initialRouteName: ScreenKey.Home,
			screens: {
				[ScreenKey.Home]: 'home',
				[ScreenKey.Routine]: 'routine',
				[NavigatorKey.ExploreNavigator]: {
					screens: {
						[ScreenKey.Explore]: 'explore',
					},
				},
				[ScreenKey.Community]: 'community',
			},
		},
		[NavigatorKey.ActivityNavigator]: {
			screens: {
				[ScreenKey.ActivityComplete]: 'activities/:activityId/complete',
				[ScreenKey.ActivityStaging]: 'activities/:activityId',
				[ScreenKey.ActivityLearning]:
					'activities/:activityId/learning/:flowStepId?/:flowStepIndex?/:subPath?',
				[ScreenKey.ActivityVariant]:
					'activities/:activityId/:variantId/:flowStepId?/:flowStepIndex?/:subPath?',
				[ScreenKey.ActivityHelp]:
					'activities/:activityId/:variantId/:flowStepId?/:flowStepIndex?/:subPath?/help',
			},
		},
		[NavigatorKey.SoundNavigator]: {
			screens: {
				[ScreenKey.EroticStories]: 'erotic-stories',
				[ScreenKey.SoundSeries]: 'sounds/:soundSeriesSlug',
				[ScreenKey.SoundEpisode]: 'sounds/:soundSeriesSlug/:soundEpisodeSlug',
			},
		},
		[NavigatorKey.CancelationNavigator]: {
			screens: {
				[ScreenKey.CancelationQuestion]: 'account/cancel-subscription',
				[ScreenKey.CancelationSubQuestion]: {
					path: 'account/cancel-subscription/sub-question',
				},
				[ScreenKey.CancelationInvestorFriendlyQuestion]: {
					path: 'account/cancel-subscription/investor-question',
				},
				[ScreenKey.CancelationDiscountOffer]: {
					path: 'account/cancel-subscription/discount',
				},
				[ScreenKey.CancelationDiscountApplied]: {
					path: 'account/cancel-subscription/discount-applied',
				},
				[ScreenKey.CancelationNoDiscount]: {
					path: 'account/cancel-subscription/confirm',
				},
				[ScreenKey.CancelationSuccess]: 'account/cancel-subscription/success',
				[ScreenKey.PostCancelationQuestion]: {
					path: 'account/cancel-subscription/efficacy/:questionNo',
					parse: {
						questionNo: Number,
					},
				},
				[ScreenKey.PostCancelationSuccess]: {
					path: 'account/cancel-subscription/efficacy/success',
				},
			},
		},
		[NavigatorKey.CheckoutNavigator]: {
			screens: {
				[ScreenKey.Checkout]: 'checkout',
				[ScreenKey.CheckoutPreface]: {
					path: 'checkout/preface/:flowStepId?/:flowStepIndex?/:subPath?',
					parse: {
						flowStepIndex: Number,
					},
				},
				[ScreenKey.CheckoutTrialOptions]: 'checkout/trial-options',
				[ScreenKey.CheckoutSubscribe]: 'checkout/subscribe',
				[ScreenKey.CheckoutSubscriptionPlans]: 'checkout/plans',
				[ScreenKey.CheckoutOneTimeOffer]: 'checkout/offer',
				[ScreenKey.CheckoutPayment]: 'checkout/payment',
				[ScreenKey.CheckoutRedirect]: 'checkout/redirect',
				[ScreenKey.CheckoutSuccess]: 'checkout/success',
			},
		},
		[NavigatorKey.DevNavigator]: {
			screens: {
				[ScreenKey.DevCMSContent]: '_dev/content',
				[ScreenKey.DevFlow]: {
					path: '_dev/flows/:flowId/:flowStepId?/:flowStepIndex?/:subPath?',
					parse: {
						flowStepIndex: Number,
					},
				},
				[ScreenKey.DevWhoops]: '_dev/whoops',
				[ScreenKey.ChangePollResponses]: '_dev/change-poll-responses',
				[ScreenKey.ContentValidation]: '_dev/content-validation',
				[ScreenKey.SeeAllActivities]: '_dev/see-all-activities',
			},
		},
		[ScreenKey.ItoMemories]: 'memories',
		[ScreenKey.StreaksCalendar]: 'streaks/calendar',
		[ScreenKey.EarnedCoins]: 'earned-coins',
		[NavigatorKey.StreaksNavigator]: {
			screens: {
				[ScreenKey.StreakGained]: 'streaks/gained',
				[ScreenKey.StreaksCommitment]: 'streaks/commitment',
			},
		},
		[NavigatorKey.SQScoreNavigator]: {
			screens: {
				[ScreenKey.SQScorePillarList]: 'score',
				[ScreenKey.SQScorePillar]: 'score/:pillar',
			},
		},
		[NavigatorKey.ChallengesNavigator]: {
			screens: {
				[ScreenKey.Challenge]: 'challenge/:challengeId',
				[ScreenKey.ChallengeSelector]: 'challenge/:challengeId/selector',
			},
		},
		[ScreenKey.NotFound]: '*',
	},
};

const linking: Linking = {
	prefixes,
	config,
};

export default linking;
