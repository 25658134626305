import { skipToken } from '@reduxjs/toolkit/query';

import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useLocalization } from '../../../hooks/useLocalization';
import { selectUserId } from '../../session';
import api from '..';

const useGetChallenges = () => {
	const userId = useAppSelector(selectUserId);
	const { timezone } = useLocalization();

	return useEndpointQuery(
		api.endpoints.getChallenges.useQuery(
			userId ? { timezone, userId } : skipToken,
		),
	);
};

export default useGetChallenges;
